import { Component, OnInit } from '@angular/core';
import { ClickworkConfiguration } from '../../config';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.html',
  styleUrls: ['./footer.scss']
})
export class FooterComponent implements OnInit {
  public brandLogo!: string;
  public brandName!: string;
  public date!: number;

  constructor(
    private config: ClickworkConfiguration) {
  }

  ngOnInit() {
    this.brandLogo = this.config.getConfigOption('brandLogo');
    this.brandName = this.config.getConfigOption('brandName');
    this.date = new Date().getFullYear();
  }
}
