import { Component, OnInit } from '@angular/core';
import { ClickworkConfiguration } from 'src/config';

@Component({
  selector: 'app-home',
  templateUrl: './home.html',
  styleUrls: ['./home.scss']
})
export class HomePage implements OnInit {
  public logo!: string;

  constructor(
    private config: ClickworkConfiguration) {
  }

  ngOnInit() {
    this.logo = this.config.getConfigOption('brandLogo');
  }
}
