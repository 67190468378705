import { Component, OnInit } from '@angular/core';
import { ClickworkConfiguration } from '../../config';

@Component({
  selector: 'app-header',
  templateUrl: './header.html',
  styleUrls: ['./header.scss']
})
export class HeaderComponent implements OnInit {
  public brandLogo!: string;

  constructor(
    private config: ClickworkConfiguration) {
  }

  ngOnInit() {
    this.brandLogo = this.config.getConfigOption('brandLogo');
  }
}
