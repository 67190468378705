export class ClickworkConfiguration {
  public applicationOptions: any;

  constructor() {
    this.applicationOptions = {
      brandName: "Madeley",
      brandLogo: "./assets/img/logo.svg",
      webUri: "https://madeley.uk/",
    }
  }

  public getConfigOption(option: any, key?: string) {
    if (key) {
      for (let i = 0; i < this.applicationOptions[option].length; i++) {
        if (this.applicationOptions[option][i].name === key) {
          return this.applicationOptions[option][i].image;
        }
      }
    }
    
    return this.applicationOptions[option];
  }
}