<div class="home">
  <div class="overlay">
    <img [src]="logo" alt="Madeley" />
    <h1>Madeley</h1>
    <h2>ad officium vocat</h2>
  </div>
</div>

<!-- <div class="family-members">
  <div class="member">
    member
  </div>
  <div class="member">
    member
  </div>
  <div class="member">
    member
  </div>
</div> -->