import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.html',
  styleUrls: ['./not-found.scss']
})
export class NotFoundPage implements OnInit {

  constructor(
    ) {
  }

  ngOnInit() {
  }
}
