import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomePage } from '../pages/home/home';

import { NotFoundPage } from '../pages/not-found/not-found';

export const routes: Routes = [
  { path: '', component: HomePage },
  
  { path: '404', component: NotFoundPage, data: { title: '404 Error' } },
  { path: '**', redirectTo: '/404' },
]

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    { enableTracing: true }
    )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
